define("js-common/utils/doc-name-parsing", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = docNameParsing;

  function getSubjectByFullName(subjects, subjName) {
    var subject = subjects.findBy('fullNameWithMiddle', subjName);

    if (subject) {
      return subject;
    }

    var subjNameLower = subjName.toLowerCase();
    subjects.forEach(function (subj) {
      if (subj.get('fullNameWithMiddle').toLowerCase().indexOf(subjNameLower) > -1) {
        subject = subj;
      }
    });
    return subject;
  }

  function getSubjectByOrderNumber(subjects, orderNumber) {
    return subjects.findBy('memberId', +orderNumber);
  }

  function docNameParsing(originalDocName, currentSubject, subjects, documentTypes) {
    var subject;
    var tabNumber;
    var docType;
    var fileName;
    var socnetName;
    var subjectFound = false;
    var docTypeFound = false;
    var sections = [];
    sections = originalDocName.split(' - ');

    if (originalDocName.indexOf('TAB') === 0) {
      var tabSections = sections;
      var tab = tabSections[0];
      tabNumber = tab.replace('TAB ', '').trim();
      sections = tabSections.slice(1);
    }

    if (currentSubject) {
      subject = currentSubject;
      subjectFound = currentSubject.get('fullNameWithMiddle').toLowerCase().includes(sections[0].toLowerCase().trim());
    } else {
      // First section is always the subject
      var possibleName = sections[0];
      var orderNumber = null;
      var checkFirstCharsAreDigits = /^(\d{1,3})|(\d{1,3})\s*\w*$/;

      if (checkFirstCharsAreDigits.test(possibleName)) {
        // get only digits for the order number
        orderNumber = possibleName.replace(/\D/g, ''); // get only non digits for the name

        possibleName = possibleName.replace(/\d/g, '');
      }

      var proposedSubject;

      if (possibleName.indexOf('(')) {
        // In case it has also the relationship in the parenthesis
        var proposedSubjectEls = possibleName.split('(');

        var _proposedSubjectEls = (0, _slicedToArray2.default)(proposedSubjectEls, 1);

        proposedSubject = _proposedSubjectEls[0];
      } else {
        proposedSubject = possibleName;
      }

      if (Ember.isPresent(proposedSubject) || Ember.isPresent(orderNumber)) {
        var subjectInList;

        if (Ember.isPresent(proposedSubject)) {
          subjectInList = getSubjectByFullName(subjects, proposedSubject.trim());
        }

        if (Ember.isPresent(orderNumber)) {
          var subjectOrderInList = getSubjectByOrderNumber(subjects, orderNumber);

          if (subjectInList) {
            if (subjectInList.get('fullNameWithMiddle') === subjectOrderInList.get('fullNameWithMiddle')) {
              subject = subjectInList;
              subjectFound = true;
            }
          } else {
            subject = subjectOrderInList;
            subjectFound = !!subjectOrderInList;
          }
        } else if (subjectInList) {
          subject = subjectInList;
          subjectFound = !!subjectInList;
        } else {
          subject = null;
        }
      }
    }

    if (!docTypeFound) {
      var proposedDocType = sections[1];

      if (proposedDocType) {
        if (proposedDocType.indexOf(' (') > -1) {
          // It has information about sonetName
          var docTypeSections = proposedDocType.split(' (');

          var _docTypeSections = (0, _slicedToArray2.default)(docTypeSections, 1);

          proposedDocType = _docTypeSections[0];
          socnetName = docTypeSections.splice(1).join(' (').replace(')', '').trim();
        }

        var trimmedProposedDocType = proposedDocType.trim().toUpperCase();
        var docTypeInList = documentTypes.findBy('value', trimmedProposedDocType);

        if (docTypeInList) {
          docType = docTypeInList.get('value');
          docTypeFound = true;
        } else {
          documentTypes.forEach(function (documentType) {
            if (documentType.get('value').indexOf(trimmedProposedDocType) >= 0) {
              docType = documentType.get('value');
              docTypeFound = true;
            }
          });
        }
      }
    }

    var newSections = [];
    sections.forEach(function (s, i) {
      if (i === 0 && !subjectFound) {
        newSections.push(sections[0].trim());
      }

      if (i === 1 && !docTypeFound) {
        newSections.push(sections[1].trim());
      }

      if (i > 1) {
        newSections.push(sections[i].trim());
      }
    });

    if (newSections.length > 0) {
      var newFileName = newSections.join(' - ');
      fileName = newFileName;
    }

    return {
      subject: subject,
      subjectName: subject ? subject.get('fullNameWithMiddle') : null,
      tabNumber: tabNumber,
      docType: docType,
      socnetName: socnetName,
      fileName: fileName
    };
  }
});