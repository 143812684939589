define("js-common/transforms/json", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        try {
          return JSON.parse(serialized);
        } catch (e) {
          console.error('Could not deserialize as JSON: ', serialized);
          return null;
        }
      } else {
        return serialized; // Null or undefined
      }
    },
    serialize: function serialize(deserialized) {
      return JSON.stringify(deserialized);
    }
  });

  _exports.default = _default;
});