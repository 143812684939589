define("js-common/models/file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr('string'),
    fileName: attr('string'),
    mimetype: attr('string'),
    size: attr('number'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),

    /**
     * calculate a friendly file size for display
     */
    friendlySize: Ember.computed('size', function () {
      var bytes = this.size;
      var decimals = 0;

      if (bytes === 0) {
        return '0 Byte';
      }

      var k = 1024; // Or 1 kilo = 1000

      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
      var i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
    })
  });

  _exports.default = _default;
});