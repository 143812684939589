define("js-common/helpers/markdown", ["exports", "@babel/runtime/helpers/esm/wrapRegExp"], function (_exports, _wrapRegExp2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.markdown = markdown;
  _exports.default = void 0;

  /*global SimpleMDE*/
  // Helper adapted from ember-simplemde/addon/helpers/simple-mde-preview.js

  /**
   * Handles blocks such as these:
   *
   *     - red
   *     - green
   *     - blue
   *
   *     - another list that is separated...
   *     - ...by some space from the previous one
   */
  function fixListBlocks(text) {
    var iteration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    if (iteration > 10) {
      return text;
    }

    var output = text.replaceAll((0, _wrapRegExp2.default)(/(([*\-])\s+.*\n)\s*(\n([*\-])\s+)/g, {
      prefix: 1,
      suffix: 3
    }), function () {
      var matches = arguments[arguments.length - 1];
      return "".concat(matches.prefix, "<br>").concat(matches.suffix);
    });

    if (text === output) {
      return output;
    }

    return fixListBlocks(output, iteration + 1);
  }

  function markdown(params
  /*, hash*/
  ) {
    (false && !(params.length === 1) && Ember.assert('This helper requires one string parameter', params.length === 1));
    var plainText = params[0];

    if (Ember.isEmpty(plainText)) {
      return '';
    }

    (false && !(Ember.typeOf(plainText) === 'string') && Ember.assert('Parameter should be string', Ember.typeOf(plainText) === 'string'));
    plainText = fixListBlocks(plainText);
    var rendered = SimpleMDE.prototype.markdown(plainText);
    return Ember.String.htmlSafe(rendered);
  }

  var _default = Ember.Helper.helper(markdown);

  _exports.default = _default;
});