define("js-common/templates/components/subject-profile-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kb//mPWl",
    "block": "{\"symbols\":[\"field\",\"idx\",\"@allowEditOrderPanel\",\"@isInModal\",\"@model\"],\"statements\":[[4,\"if\",[[25,[\"showSubjectType\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row form-group required p-0 m-0 mb-2\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"col-sm-3 control-label\"],[9],[0,\"\\n            Subject Type:\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"data-test-subject-type\",\"\"],[11,\"class\",\"col-sm-7 pr-0\"],[9],[0,\"\\n            \"],[5,\"edit-subject-type\",[],[[\"@isInModal\",\"@model\"],[[24,4,[]],[24,5,[]]]]],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[25,[\"sortedMatterFields\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"subject-profile-edit/row\",null,[[\"matterField\",\"model\",\"analyticsData\",\"phrases\",\"fieldValueChanged\",\"dataParser\",\"showLockIcon\",\"isInModal\",\"allowEditOrderPanel\"],[[24,1,[]],[25,[\"model\"]],[25,[\"analyticsData\"]],[25,[\"phrases\"]],[25,[\"fieldValueChanged\"]],[25,[\"dataParser\"]],[25,[\"showLockIcon\"]],[24,4,[]],[24,3,[]]]]],false],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"model\",\"isNew\"]],[29,\"eq\",[[24,2,[]],[25,[\"conflictCheckAfter\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[5,\"subject-conflict-check\",[],[[\"@firstName\",\"@lastName\",\"@hasConflict\"],[[25,[\"model\",\"firstName\"]],[25,[\"model\",\"lastName\"]],[23,\"hasConflict\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-profile-edit.hbs"
    }
  });

  _exports.default = _default;
});