define("js-common/templates/components/subject-conflict-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pj1s9EFC",
    "block": "{\"symbols\":[],\"statements\":[[5,\"conflict-check\",[],[[\"@class\",\"@subjectNames\",\"@hasConflict\",\"@moreLink\"],[[23,\"class\"],[23,\"subjectNames\"],[23,\"hasConflict\"],[23,\"moreLink\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-conflict-check.hbs"
    }
  });

  _exports.default = _default;
});