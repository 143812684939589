define("js-common/templates/components/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lb7XZo1c",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"hasAvatar\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"img\",null,[[\"src\",\"class\",\"width\",\"height\"],[[25,[\"avatarUrl\"]],[25,[\"classStr\"]],[25,[\"width\"]],[25,[\"height\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"svg-jar\",[\"img_profile_male\"],[[\"class\",\"width\",\"height\"],[[25,[\"classStr\"]],[25,[\"width\"]],[25,[\"height\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/user-avatar.hbs"
    }
  });

  _exports.default = _default;
});