define("js-common/helpers/user-avatar-url", ["exports", "js-common/utils/get-app-url"], function (_exports, _getAppUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Fetch the Avatar image URL for a user
   */
  var _default = Ember.Helper.extend({
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    compute: function compute(params, hash) {
      var _this = this;

      var ENV = this.ENV;
      var user = hash.user.content;

      if (typeof user === 'undefined' || user === null || user.get('id') === null) {
        return null;
      }

      var url = null;

      if (user.get('avatarId')) {
        this.get('ajax').post('/users/generate-nonce', {
          contentType: 'application/json'
        }).then(function (response) {
          return (0, _getAppUrl.default)(ENV, "/files/".concat(_this.get('fileId'), "/preview/1?token=").concat(response.token));
        }).catch(function (error) {
          console.error('Error generating download nonce:', error); // Handle error - display message to user, etc.

          _this.get('notify').error('Error initiating download. Please try again.');
        });
      }

      return null;
    }
  });

  _exports.default = _default;
});