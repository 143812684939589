define("js-common/models/resource", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    childTable: attr('string'),
    syncId: attr('number'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    viewable: attr('number'),
    doNotUse: attr('number'),
    // should be a relationships
    // createdById: attr('number'),
    updatedById: attr('number'),
    copiedFromId: attr('number'),
    // relationships
    createdBy: belongsTo('user', {
      async: true
    }),
    copiedFrom: belongsTo('resource', {
      async: true
    }),
    matter: belongsTo('matter', {
      async: false
    }),
    resourceHasTags: _emberData.default.hasMany('resource-has-tag', {
      async: false
    })
  });

  _exports.default = _default;
});