define("js-common/models/employee", ["exports", "jquery", "ember-data"], function (_exports, _jquery, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    department: attr('string'),
    role: attr('string'),
    rate: attr('number'),
    hourlyCost: attr('number'),
    employeeNumber: attr('string'),
    juryBonusAmount: attr('number'),
    bonusThreshold: attr('number'),
    standardBonusAmount: attr('number'),
    billingName: attr('string'),
    // user fields
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    userName: attr('string'),
    password: attr('string'),
    salt: attr('string'),
    passwordExpired: attr('string'),
    status: attr('string'),
    lastLogin: attr('string'),
    lastLogout: attr('string'),
    tmNum: attr('number'),
    tmType: attr('string'),
    salesforceId: attr('string'),
    token: attr('string'),
    tokenExpires: attr('string'),
    avatarId: attr('number'),
    // relationships
    tasks: hasMany('task', {
      inverse: 'assignedTo'
    }),
    user: belongsTo('user', {
      async: true,
      inverse: 'employee'
    }),
    userAddrs: hasMany('user-addr', {
      async: false
    }),
    userNumbers: hasMany('user-number', {
      async: false
    }),
    updatedBys: hasMany('billable-group', {
      async: true,
      inverse: 'updatedBy'
    }),
    createdBys: hasMany('billable-group', {
      async: true,
      inverse: 'createdBy'
    }),
    approvedBys: hasMany('billable-group', {
      async: true,
      inverse: 'approvedBy'
    }),
    // calculated
    fullName: Ember.computed('firstName', 'lastName', function () {
      var fullName = "".concat(this.firstName, " ").concat(this.lastName);
      return (0, _jquery.default)('<div/>').html(fullName).text();
    }),
    shortName: Ember.computed('firstName', 'lastName', function () {
      var first = this.firstName || '';
      var last = this.lastName || '';
      return first + ' ' + last.substring(0, 1);
    }),
    isActive: Ember.computed.equal('status', 'Active')
  });

  _exports.default = _default;
});