define("js-common/components/x-upload", ["exports", "jquery", "ember-uploader/uploaders/uploader", "js-common/templates/components/x-upload", "js-common/helpers/show-filesize", "ember-component-inbound-actions/inbound-actions", "js-common/utils/valid-upload-file-extensions"], function (_exports, _jquery, _uploader, _xUpload, _showFilesize, _inboundActions, _validUploadFileExtensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_UPLOAD_SIZE = 5120 * 1024 * 1024; // 5GB

  /**
   * Component intended to replace document-uploader
   * it will have all the logic itself, without
   * the need of external dependencies like the mixin
   * or functions in controllers.
   *
   * To communicate with the outside world, it's just
   * going to send actions to be catched by the
   * controller or router where is used.
   *
   * It will have some options depending where is
   * used, in order to ask for more information
   *
   * i.e.
   *
   * - Automatically link uploads to the subject as argument
   *
   * {{x-upload matter=matter subject=subject documentTypes=listService.fileTypes
   *   cancelUpload='cancelUpload'
   *   beforeUpload='beforeUpload'
   *   afterUpload='afterUpload'
   *   errorUpload='errorUpload'}}
   *
   * - Ask for subject before upload
   *
   * {{x-upload matter=model documentTypes=listService.fileTypes
   *   cancelUpload='cancelUpload'
   *   beforeUpload='beforeUpload'
   *   afterUpload='afterUpload'
   *   errorUpload='errorUpload'}}
   *
   * - For billable group edits:
   *
   * {{x-upload matter=model documentTypes=false subject=false endPoint='receipts'
   *   cancelUpload='cancelUpload'
   *   beforeUpload='beforeUpload'
   *   afterUpload='afterUpload'
   *   errorUpload='errorUpload'}}
   */

  var _default = Ember.Component.extend(_inboundActions.default, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    counts: Ember.inject.service(),
    notify: Ember.inject.service(),
    metrics: Ember.inject.service(),
    hideTitle: false,
    jsStyle: false,
    showCancelButton: true,
    showUploadSize: true,
    isReceipt: false,

    /**
     * used to get configuration from the application
     *
     * @property
     */
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    isClientPortal: Ember.computed('clientPortal', function () {
      if (this.clientPortal) {
        return true;
      }

      return false;
    }),

    /** component linking properties */
    filesDisplayComponent: 'x-upload.body-list',

    /**
     * set the right layout to be rendered with this component
     */
    layout: _xUpload.default,

    /**
     * List of files ready to be uploaded
     *
     * @property
     * @type Array
     */
    filesToUpload: [],

    /**
     * @property
     * @type boolean
     *
     * allow hide the upload button to send the upload action from outside
     */
    hideUploadButton: false,

    /**
     * @property
     * @type boolean
     *
     * not show as box to show as a unit when replacing a file
     */
    noBox: false,

    /**
     * check if the upload button should be shown
     *
     * @property
     * @type boolean
     */
    showUploadButton: Ember.computed('uploader', 'filesToUpload.[]', 'hideUploadButton', function () {
      if (this.hideUploadButton) {
        return false;
      } else {
        return Ember.isPresent(this.uploader) && this.get('filesToUpload.length') > 0;
      }
    }),

    /**
     * @property
     *
     * check if documentTypes and subject is false to
     * not show any of that info like in billable/edit
     */
    shouldAskForInfo: Ember.computed('subject', 'documentTypes', function () {
      return !(this.subject === false && this.documentTypes === false);
    }),

    /**
     * case where document should be uploaded to
     *
     * @property
     * @type model/Matter
     */
    matter: null,

    /**
     * list of document types to show on the list
     *
     * @property
     */
    documentTypes: [],

    /**
     * subject that document should be linked to automatically
     * without asking, if not provided the component will show
     * a list of subjects on the provided case
     *
     * @property
     * @type model/Subject
     */
    subject: null,

    /**
     * if the user can upload multiple files
     */
    allowMultiple: true,

    /**
     * check if subject is set so we don't ask
     */
    askForSubject: Ember.computed.empty('subject'),

    /**
     * check if subject is false, so is not related to subject
     */
    notRelatedToSubject: Ember.computed('subject', function () {
      return this.subject === false;
    }),

    /**
     * check if we need to ask for documentType
     */
    askForDocumentType: Ember.computed('documentTypes', function () {
      return this.documentTypes !== false;
    }),

    /**
     * list of subjects to be show to select one
     * @property
     * @type Array
     */
    subjects: [],

    /**
     * fields to sort by the subjects
     */
    subjectsSortBy: ['lastName', 'firstName'],

    /**
     * list of subjectes sorted by subjectsSortBy
     */
    sortedSubjects: Ember.computed.sort('subjects', 'subjectsSortBy'),

    /**
     * object in charge of the upload details
     * communication with server, send of the form data, etc
     *
     * @property
     * @type Uploader
     */
    uploader: null,

    /**
     * progress bar size / 100%
     */
    progress: 0,
    isPublic: false,
    isPrivate: false,

    /**
     * computed for style the progressbar
     */
    progressStyle: Ember.computed('progress', function () {
      var style = 'width: ' + this.progress + '%;';
      return Ember.String.htmlSafe(style);
    }),

    /**
     * Fetch subjects if there is no one provided
     *
     * @method
     */
    initializeComponent: Ember.on('init', function () {
      var _this = this;

      var self = this;
      var store = this.store;
      Ember.run.next(function () {
        _this.set('filesToUpload', []);
      });

      if (Ember.isEmpty(this.subject)) {
        this.set('subjects', store.query('subject', {
          matter_id: this.get('matter.id')
        }));
      } // Initialize the uploader component


      var uploader = _uploader.default.create({
        url: this.uploadURL
      });

      uploader.on('didUpload', function () {
        Ember.run(function () {
          if (!self.get('isDestroyed') && !self.get('isDestroying')) {
            self.set('filesToUpload', []);
            self.set('progress', 0);
          }
        });
      });
      uploader.on('progress', function (e) {
        Ember.run(function () {
          if (!self.get('isDestroyed') && !self.get('isDestroying')) {
            self.set('progress', e.percent);
          }
        });
      });
      this.set('uploader', uploader);
      uploader.set('ajaxSettings', this.ajaxSettings());
    }),
    ajaxSettings: function ajaxSettings(url, params, method) {
      var env = this.get('ENV.environment');
      var cikey = this.get('ENV.cikey');
      return function (url, params, method) {
        var self = this;
        return {
          url: url,
          type: method || 'POST',
          contentType: false,
          processData: false,
          xhr: function xhr() {
            var xhr = _jquery.default.ajaxSettings.xhr();

            xhr.upload.onprogress = function (e) {
              self.didProgress(e);
            };

            self.one('isAborting', function () {
              xhr.abort();
            });
            return xhr;
          },
          data: params,
          beforeSend: function beforeSend(request) {
            if (env === 'test') {
              request.setRequestHeader('X-CI-KEY', cikey);
            }
          }
        };
      };
    },
    totalUploadSize: Ember.computed('filesToUpload.[]', function () {
      var filesToUpload = this.filesToUpload;
      return filesToUpload.reduce(function (accumulator, file) {
        return accumulator + file.size;
      }, 0);
    }),

    /**
     * action name used to notify the controller
     * about the component being ready to upload
     * and called just before upload
     *
     * @event
     */
    beforeUpload: '',
    //  this.sendAction('beforeUpload', this.get('filesToUpload'));

    /**
     * action name used to notify the controller
     * about the component finished the upload
     *
     * @event
     */
    afterUpload: '',

    /**
     * action name used to notify the controller
     * in case of error during upload
     *
     * @event
     */
    errorUpload: '',

    /**
     * @property
     *
     * endpoint to hit for upload documents, default: documents
     */
    endPoint: 'documents',

    /**
     * API upload url
     *
     * @property
     */
    uploadURL: Ember.computed('ENV', function () {
      var ENV = this.ENV;
      return ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/' + this.endPoint;
    }),
    isExtensionValid: function isExtensionValid(ext) {
      if (this.isReceipt) {
        return _validUploadFileExtensions.receiptExtensions.includes(ext);
      } else {
        return _validUploadFileExtensions.allExtensions.includes(ext);
      }
    },
    getFileExtension: function getFileExtension(filename) {
      var parts = filename.split('.');
      return parts[parts.length - 1];
    },

    /** *************** ACTIONS of the Component ********************/
    actions: {
      cancelUpload: function cancelUpload() {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.set('filesToUpload', []);
        this.set('progress', 0);
        this.sendAction('cancelUpload');
      },
      deleteFile: function deleteFile(file) {
        this.filesToUpload.removeObject(file);
      },
      upload: function upload() {
        var _this2 = this;

        var self = this;
        var filesToUpload = this.filesToUpload;

        if (filesToUpload === null || filesToUpload.length === 0) {
          return;
        }

        var uploadSize = filesToUpload.reduce(function (accumulator, file) {
          return accumulator + file.size;
        }, 0);

        if (uploadSize > MAX_UPLOAD_SIZE) {
          this.notify.warning('Documents exceed the file size limit on the server, please reduce the number of files or the file size to under ' + (0, _showFilesize.formatFilesize)(MAX_UPLOAD_SIZE));
          return;
        } // check if extension is valid


        var invalidExtensions = 0;
        filesToUpload.forEach(function (file) {
          var ext = _this2.getFileExtension(file.file.name);

          if (!ext || !_this2.isExtensionValid(ext.toLowerCase())) {
            var message = 'File ' + file.file.name + ' has an invalid extension';

            if (_this2.isReceipt) {
              message += ', only allowed: ' + _validUploadFileExtensions.receiptExtensions.join(', ');
            }

            _this2.notify.warning(message);

            invalidExtensions++;
          }
        });

        if (invalidExtensions > 0) {
          this.notify.error(invalidExtensions + ' files have an invalid file extension.');
          return;
        }

        self.sendAction('beforeUpload', filesToUpload);
        var extraParameters = {
          matters_id: this.get('matter.id'),
          token: this.get('session.data.authenticated.token')
        };
        filesToUpload.forEach(function (file, index) {
          extraParameters['name_' + index] = file.get('name');
          extraParameters['notes_' + index] = file.get('notes');
          extraParameters['description_' + index] = Ember.isPresent(file.get('description')) ? file.get('description') : '';

          if (!self.get('askForSubject')) {
            file.set('subject', self.get('subject'));
          }

          if (self.get('isClientPortal')) {
            extraParameters['docType_' + index] = 'CLIENT_UPLOAD';
            extraParameters['viewable_' + index] = 1;
          } else {
            extraParameters['docType_' + index] = file.get('docType');
            extraParameters['viewable_' + index] = file.get('viewable');
            extraParameters['do_not_use_' + index] = file.get('doNotUse') ? 1 : 0;
            extraParameters['reportId_' + index] = Ember.isPresent(file.get('report.id')) ? file.get('report.id') : '';
            extraParameters['tabNumber_' + index] = Ember.isPresent(file.get('tabNumber')) ? parseInt(file.get('tabNumber'), 10) : '';
          }

          var scrapeId = file.get('scrapeId');

          if (scrapeId) {
            extraParameters['scrape_id_' + index] = scrapeId;
          }

          extraParameters['subject_id_' + index] = file.get('subject.id');
          extraParameters['overwrite_document_id_' + index] = file.get('overwriteDocumentId'); // This is now set using the document-edit-table beforeUpload

          var tags = file.get('tags');

          if ('tags' in file && Ember.isPresent(tags)) {
            extraParameters['tag_ids_' + index] = tags.mapBy('id');
          }

          if (Ember.isPresent(file.get('extra'))) {
            for (var key in file.get('extra')) {
              extraParameters[key + '_' + index] = file.get('extra.' + key);
            }
          }
        });
        this.uploader.upload(filesToUpload.map(function (file) {
          Ember.get(_this2, 'metrics').trackEvent({
            category: 'Document',
            action: 'upload',
            label: file.get('name')
          });
          return file.file;
        }), extraParameters).then(function (response) {
          if (Ember.typeOf(response) === 'object' && 'errors' in response && response.errors.length > 0) {
            self.sendAction('errorUpload', response);
          } else {
            self.sendAction('afterUpload', response);
          }
        }, function (error) {
          self.sendAction('errorUpload', error);
        });
      },
      remove: function remove(fileToRemove) {
        this.filesToUpload.removeObject(fileToRemove);
      }
    }
  });

  _exports.default = _default;
});