define("js-common/models/quick-filter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    user: belongsTo('user', {
      async: true
    }),
    name: attr('string'),
    values: attr('json'),
    filterType: attr('string'),
    isShared: attr('boolean', {
      defaultValue: false
    }),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate')
  });

  _exports.default = _default;
});