define("@smith-carson/ui/pods/components/modals/case-filters/component", ["exports", "@smith-carson/ui/pods/components/modals/case-filters/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance for filtering cases in a modal sidebar.
  
      @module Modals::CaseFiltersComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['side-panel', 'side-panel-left'],
    layout: _template.default,
    actions: {
      setQuickFilterToUpdate: function setQuickFilterToUpdate() {
        document.querySelector('.case-filters__filters-list').setAttribute('data-quick-filter-id', this.quickFilter.id);
      }
    }
  });

  _exports.default = _default;
});